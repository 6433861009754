<template>
  <el-dialog v-model="dialogFormVisible" :title="title" @close="closeDialog">
    <el-form :model="addDeviceForm" ref="addDeviceRef" :rules="addDevicerules">
      <el-form-item label="设备名称" :label-width="formLabelWidth" prop="name">
        <el-input
          v-model="addDeviceForm.name"
          autocomplete="off"
          ref="name"
          placeholder="请填写设备名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="设备编码" :label-width="formLabelWidth" prop="code">
        <el-input
          v-model="addDeviceForm.code"
          autocomplete="off"
          ref="name"
          placeholder="请填写设备编码"
        ></el-input>
      </el-form-item>
      <el-form-item label="设备类型" :label-width="formLabelWidth" prop="type">
        <el-select
          v-model="addDeviceForm.type"
          placeholder="请选择设备类型"
          clearable
        >
          <el-option label="电教" :value="1"></el-option>
          <el-option label="总务" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="设备地点"
        :label-width="formLabelWidth"
        prop="address"
      >
        <el-input
          v-model="addDeviceForm.address"
          autocomplete="off"
          ref="name"
          placeholder="请填写设备地点"
        ></el-input>
      </el-form-item>
      <el-form-item label="设备厂商" :label-width="formLabelWidth" prop="brand">
        <el-input
          v-model="addDeviceForm.brand"
          autocomplete="off"
          ref="name"
          placeholder="请填写设备厂商"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="厂商电话"
        :label-width="formLabelWidth"
        prop="brandMobile"
      >
        <el-input
          v-model="addDeviceForm.brandMobile"
          autocomplete="off"
          ref="name"
          placeholder="请填写厂商电话"
        ></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="cancel()">取消</el-button>
        <el-button type="primary" @click="save()">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { ref } from "vue";
import { httpService } from "@/utils/httpService";
import { ElMessage, ElForm } from "element-plus";
import { vxRule } from "@/utils/validator";

export default {
  name: "AddDevice",
  components: { ElForm, ElMessage },
  data() {
    return {
      dialogFormVisible: ref(false),
      formLabelWidth: "120px",
      title: "",
      personnelType: "",
      addDeviceForm: {
        name: "",
        code: "",
        type: "",
        address: "",
        brand: "",
        brandMobile: "",
      },
      // 校验规则
      addDevicerules: {
        name: vxRule(true, null, "blur"),
        code: vxRule(true, null, "blur"),
        address: vxRule(true, null, "blur"),
        type: vxRule(true, null, "blur"),
      },
    };
  },
  methods: {
    closeDialog() {
      this.clear();
    },
    cancel() {
      this.dialogFormVisible = false;
      this.clear();
    },
    getDeviceById(id) {
      httpService("/api/device/queryById", { id: id }, "get").then((data) => {
        this.addDeviceForm = data;
      });
    },
    clear() {
      this.dialogFormVisible = false;
      //清空form
      this.addDeviceForm.id = "";
      this.addDeviceForm.name = "";
      this.addDeviceForm.code = "";
      this.addDeviceForm.type = "";
      this.addDeviceForm.address = "";
      this.addDeviceForm.brand = "";
      this.addDeviceForm.brandMobile = "";
    },
    save() {
      this.$refs.addDeviceRef.validate((valid) => {
        if (valid) {
          let params = new FormData(); //创建一个form对象,必须是form对象否则后端接受不到数据
          params.append("name", this.addDeviceForm.name);
          params.append("code", this.addDeviceForm.code);
          params.append("type", this.addDeviceForm.type);
          params.append("address", this.addDeviceForm.address);
          params.append("brand", this.addDeviceForm.brand);
          params.append("brandMobile", this.addDeviceForm.brandMobile);
          if (this.addDeviceForm.id) {
            params.append("id", this.addDeviceForm.id);
            httpService("/api/device/edit", params, "post").then((data) => {
              if (data.code == 200) {
                ElMessage.success("修改成功");
                this.clear();
                //刷新父组件的数据
                this.$parent.getDeviceList();
              } else {
                ElMessage.error("修改失败");
              }
            });
          } else {
            httpService("/api/device/add", params, "post").then((data) => {
              if (data.code == 200) {
                ElMessage.success("添加成功");
                this.clear();
                //刷新父组件的数据
                this.$parent.getDeviceList();
              } else {
                ElMessage.error("添加失败");
              }
            });
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style>
.el-select {
  width: 100%;
}
</style>